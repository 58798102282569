import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const hdfcCacheApiInstance = axios.create({
  baseURL: travel_url,
});
hdfcCacheApiInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getHdfceSessionApi = (data, pageNo = 1, size = 10) =>
  hdfcCacheApiInstance.post(
    `/hdfc/session/filter?page=${pageNo}&size=${size}`,
    data
  );
export const addHdfceSessionApi = (data) =>
  hdfcCacheApiInstance.post(`/hdfc/session/save`, data);
export const updateHdfceSessionApi = (data) =>
  hdfcCacheApiInstance.put(`/hdfc/session/update`, data);
