import React, { useEffect, useState } from "react";
import Pagination from "../../CustomComponent/Pagination";
import { useParams } from "react-router-dom";
import PaymentTableInfo from "../../Molecule/PaymentDetail/PaymentService/PaymentTableInfo";
import Loading from "../../CustomComponent/Loading";
import axios from "axios";
import { decryptDataWithMode } from "../../utils/decryptData";
import PaymentServiceFilter from "../../Molecule/PaymentDetail/PaymentService/PaymentServiceFilter";
import { getAllPaymentGateListApi } from "../../Services/Api/paymentGateway";
import { pay_int_url } from "../../Host";
import { commonheader } from "../../utils/header";
import Modals from "../../CustomComponent/Modals";
import AuthReversalModal from "../../Molecule/Apitracking/PaymentServices/AuthReversalModal";
const PaymentService = () => {
  const query = useParams();
  const [paymentData, setPaymentData] = useState({
    totalPage: 1,
    contentData: [],
  });
  const [loading, setLoading] = useState(true);
  //   const
  const [filterObj, setFilterObj] = useState({
    gateway: "",
    status: "",
    orderId: "",
    product: "",
  });
  const [allGatewayList, setAllGatewayList] = useState([]);
  const [showAuth, setShowAuth] = useState(false);
  const fetchAllPaymentGate = async () => {
    try {
      const { data } = await getAllPaymentGateListApi();
      data.data ? setAllGatewayList(data.data) : setAllGatewayList([]);
    } catch (error) {}
    // setLoading(false);
  };
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${pay_int_url}/paymentList?page=${query.id || 1}&size=10`,
        filterObj,
        {
          headers: commonheader(),
        }
      );
      console.log({ data });
      const scrKey = "dHJhdm9QQ0lEU1MyMDIzMjRwYXltZW50";
      const dcryptData = decryptDataWithMode(data.data, scrKey);
      console.log({});
      setPaymentData({
        ...paymentData,
        totalPage: dcryptData.totalPages,
        contentData: dcryptData.content,
      });
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    fetchAllPaymentGate();
  }, []);
  useEffect(() => {
    if (query.id) {
      fetchData();
    }
  }, [query]);
  return (
    <div>
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        {loading && <Loading title="loading..." />}
        <PaymentServiceFilter
          allGatewayList={allGatewayList}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          fetchData={(e) => {
            e.preventDefault();
            fetchData();
          }}
        />
        {paymentData.contentData.length > 0 && (
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => setShowAuth(true)}
            >
              Auth Reversal
            </button>
          </div>
        )}
        <PaymentTableInfo paymentData={paymentData} />
      </div>
      <Modals
        show={showAuth}
        backdrop={false}
        HandleClose={() => setShowAuth(false)}
        ModalBody={
          <AuthReversalModal fetchData={fetchData} setShowAuth={setShowAuth} />
        }
        title={"Auth Reversal"}
      />
    </div>
  );
};

export default PaymentService;
