import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const influencerCouInstance = axios.create({
  baseURL: travel_url,
});
influencerCouInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getInfluencerCouponsListApi = () => {
  return influencerCouInstance.get("/coupon/influencer");
};
export const addInfluencerCouponApi = (data) => {
  return influencerCouInstance.post("/coupon/influencer", data);
};
export const UpdateInfluencerCouponApi = (data) => {
  return influencerCouInstance.put("/coupon/influencer", data);
};
