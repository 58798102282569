import React from "react";
import Login from "./Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Views/Dashboard";
import Apitracking from "./Views/ApiTracking";
import AutoUser from "./Views/AutoUser";
import BookingList from "./Views/BookingList";
import User from "./Views/Users";
import PaymentDetail from "./Views/Payment/PaymentDetail";
import Googlebooking from "./Views/Googlebooking";
import Faremanage from "./Views/Faremanage";
import MainDashboard from "./Views/MainDashboard";
import PaymentSwitcher from "./Views/PaymentSwitcher";
import Gateway from "./Views/Gateway";
import Payu from "./Views/Payu";
import CcAvenue from "./Views/Cc-avenue";
import SupplierResponse from "./Views/SupplierResponse";
import { useState } from "react";
import { useEffect } from "react";
import GuardedRoute from "./GuardedRoutes";
import MarkUpDetails from "./Views/MarkUpDetails";
import axios from "axios";
import { travel_url } from "./Host";
import Main from "./Main";

function App() {
  const [isAutheticated, setisAutheticated] = useState(false);
  const url = useParams();

  // useEffect(() => {

  // }, [url]);

  // function authentication(status){
  //   localStorage.length != 0
  //   ? setisAutheticated(status)
  //   : setisAutheticated(status);
  // }

  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
