import { Modal, Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Lottie from "react-lottie";
import loader from "../../Image/93134-not-found.json";
import { getApihitStats } from "../../Apis/getApihitStats";
import HitApiModal from "./HitApiModal";
import { saveApihitStats } from "../../Apis/saveApihitStats";
import { getAllConfig } from "../../Apis/getAllConfig";
import DashboardData from "../Dashboard/Dashboard";
import Select from "../../CustomComponent/Select";
import { UpdateManageAPI } from "../../Apis/Urls";
import { UpdateMeta } from "../../Apis/Meta/UpdateMeta";
import Loading from "../../CustomComponent/Loading";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import useGetRangeList from "../../hooks/CachemenagementHook/useGetRangeList";
import RangeList from "./RangeList";
import { getAllPreferAirline } from "../../Apis/getAllPreferAirline";
import PreferedAirline from "./PreferedAirline";
import SiteTracking from "./components/Site/SiteTracking";
import BlockAirport from "./components/BlockAirport/BlockAirport";
import FareIdentifier from "../../Atom/ApitrackingDev/FareIdentifier";
import PortalConfig from "./PortalConfig/PortalConfig";
import IPWhiltelist from "./IPWhitelist/Index";
import HoldTicketHandler from "./HoldTicketHandler/HoldTicketHandler";
import ContentVisibleMgt from "./ContentVisibilityMgmt/ContentVisibleMgt";
import PaymentGateway from "./PaymentGateway/paymentGateay";
import MetaSuppMap from "./MetaSupplierMapping/MetaSuppMap";
import GateSaveResponseMap from "./GatewaySaveResponseMapping/GateSaveResponseMap";
import InsuranceHandler from "./InsurnaceHandlerMarkup/InsuranceHandler";
import InsuranceBenefits from "./InsuranceBenefitls/Index";
import Insurance from "./InsuranceComp/Insurance";
import IconHandler from "./IconHandler/IconHandler";

const ApiTracking = ({ activetab }) => {
  // ______________________________________STates ______________________________________

  const [metaData, setMetaData] = useState([]);
  const [metaForUpdate, setMetaForUpdate] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isOn, setOn] = useState("");
  const [show, setShow] = useState(false);
  const [sourcesModal, setSourcesModal] = useState(false);
  const [APIvalue, setAPIvalue] = useState("");
  const [APIModal, setAPIModal] = useState(false);
  const [source, setSource] = useState("Select Meta");
  const [Value, setValue] = useState("");
  const [sourceNameValue, setSourceNameValue] = useState("");
  const [state, setState] = useState("");
  const [saveticketHandlerData, setSaveticketHandlerData] = useState([]);
  const [updatedMetaState, setUpdatedMetaState] = useState([]);
  const [ApiData, setApiData] = useState("");
  const [ApiName, setApiName] = useState("");
  const [allApi, setAllApi] = useState("");
  const [allSource, setAllSource] = useState([]);
  const [sources, setSources] = useState({});
  const [sourceStatus, setSourcestatus] = useState(false);
  const [suppliers, setSuppliers] = useState({});
  const [SuppliersStatus, setSuppliersStatus] = useState(false);
  const [hitStatus, setHitStatus] = useState([]);
  const [preferairline, setPreferAirline] = useState([]);
  const [apiHitShow, setApiHitShow] = useState(false);
  const [sourceApihit, setSourceApihit] = useState("");
  const [supplierApihit, setSupplierApihit] = useState("");
  const [hitLimitValue, setHitLimitValue] = useState();
  const [getAllConfigData, setGetAllConfigData] = useState([]);
  const [error, setError] = useState("None");
  const [allsourceModalButton, setAllsourceModalButton] = useState(true);
  const [supplierAPIButton, setsupplierAPIButton] = useState(true);
  const [istimeout, setIsTimeOut] = useState("");
  const [timeOut, setTimeOut] = useState("");
  // Filter
  const [getSourceValue, setGetSourceValue] = useState("");
  const [getSupplierValue, setGetSupplierValue] = useState("");

  // Switch loading
  const [ticketingStatus, setTicketingStatus] = useState(false);

  // Custom Hooks
  const getRangeList = useGetRangeList();

  // ______________________________________STates ______________________________________

  // _______________________________function of all the component_______________________________

  // _______________________________MODAL ALL_______________________________

  const HandleShow = () => {
    setShow(true);
  };
  const HandleClose = () => {
    setApiData("");
    setApiName("");
    setShow(false);
  };

  const OpenSources = () => {
    setSourcesModal(true);
  };

  const closeSources = () => {
    setSourcesModal(false);
  };

  const OpenAPIModal = () => {
    setAPIModal(true);
  };

  const closeAPIModal = () => {
    setAPIModal(false);
  };
  // _______________________________MODAL ALL_______________________________

  // _______________________________target value_______________________________

  const handleSwitch = (e, item) => {
    const { name, value } = e.target;
    if (
      (item.status === true && name === "status") ||
      (item.live === true && name === "live")
    ) {
      const body =
        name === "live" ? { ...item, live: false } : { ...item, status: false };
      setState(false);
      UpdateMeta({
        loading: setTicketingStatus,
        state: setUpdatedMetaState,
        url: UpdateManageAPI,
        method: "PUT",
        body: body,
      });
    } else if (
      (item.status === false && name === "status") ||
      (item.live === false && name === "live")
    ) {
      const body =
        name === "live" ? { ...item, live: true } : { ...item, status: true };
      setState(false);
      UpdateMeta({
        loading: setTicketingStatus,
        state: setUpdatedMetaState,
        url: UpdateManageAPI,
        method: "PUT",
        body: body,
      });
    }
    // UpdateMeta(item);
  };
  // ---------------TimeOut--------------
  const handleTimeOut = (item) => {
    const body = { ...istimeout, timeOut: timeOut };
    UpdateMeta({
      loading: setTicketingStatus,
      state: setUpdatedMetaState,
      url: UpdateManageAPI,
      method: "PUT",
      body: body,
    });
    setIsTimeOut("");
  };

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const SourceName = (e) => {
    setSourceNameValue(e);
    if (e != "") {
      setAllsourceModalButton(false);
    } else {
      setAllsourceModalButton(true);
    }
  };

  const getapitextValue = (e) => {
    setAPIvalue(e);
    if (e != "") {
      setsupplierAPIButton(false);
    } else {
      setsupplierAPIButton(true);
    }
  };

  // _______________________________target value_______________________________

  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */
  /*------------------------------------------------------------------ GET META */
  const GetAllMeta = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAllManageAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setMetaData(result.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(
          "There is Some Technical Error, Please contact with Technical Team"
        );
        setIsLoading(false);
      });
  };
  /* -----------------------------------Get Source------------------------------ */
  const GetAllSource = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSource(result);
      })
      .catch((error) => {});
  };
  /* -----------------------------------Get Source------------------------------ */
  /* -----------------------------------Get API------------------------------ */
  const GetAllAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setAllApi(result);
        }
      })
      .catch((error) => {});
  };
  /* -----------------------------------Get API------------------------------ */

  /*------------------------------------------------------------------ GET Sources */
  const GetAllSources = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setSources(result);
          setSourcestatus(true);
        }
      })
      .catch((error) => {
        setError(
          "There is Some Technical Error, Please contact with Technical Team"
        );
        setIsLoading(false);
      });
  };
  /*------------------------------------------------------------------ GET Sources */

  /*------------------------------------------------------------------ GET GetSupplierAPI */
  const GetSupplierAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setSuppliers(result);
          setSuppliersStatus(true);
        }
      })
      .catch((error) => {});
  };
  /*------------------------------------------------------------------ GET GetSupplierAPI */

  const saveSourceName = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: sourceNameValue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          setSourcesModal(false);
          GetAllSources();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const saveAPIValue = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: APIvalue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          setAPIModal(false);
          GetSupplierAPI();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {
        console.log("error->", error);
      });
  };
  /*------------------------------------------------------------------ GET META */

  /*------------------------------------------------------------------ SAVE META */
  const saveMeta = () => {
    if (
      ApiData == null ||
      ApiData == "" ||
      ApiName == null ||
      ApiName == "" ||
      Value == null ||
      Value == ""
    ) {
      alert("All fields are required");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      source: ApiName,
      api: ApiData,
      status: Value,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveManageAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          GetAllMeta();
          HandleClose();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {});
  };

  /*------------------------------------------------------------------ SAVE META */

  /*---------------------------------------------------------------- Update META */
  // const UpdateMeta = async (item) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("x-api-key", localStorage.getItem("token"));
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     source: item.source,
  //     api: item.api,
  //     status: state,
  //   });

  //   var requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const dataapi = await fetch(`${travel_url}/updateManageAPI`, requestOptions);

  //   const updatedata = await dataapi.json();
  //   if (updatedata) {
  //     setUpdatedMetaState(updatedata);
  //     if (updatedata) {
  //       alert(updatedata.msg);
  //     }
  //   } else {
  //     alert(updatedata.msg);
  //   }
  // };

  const DeleteMeta = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,

      redirect: "follow",
    };

    const dataapi = await fetch(
      `${travel_url}/deleteManageAPI?id=${id}`,
      requestOptions
    );

    const deletedata = await dataapi.json();
    alert(deletedata.msg);
    GetAllMeta();
  };

  /*---------------------------------------------------------------- Update META */

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleApihit = () => {
    GetAllSources();
    setApiHitShow(true);
    // setApiHitShow(false);
  };

  const handlecloseApihit = () => {
    setApiHitShow(false);
  };

  const saveHitApiData = () => {
    // getAllConfig({ setGetAllConfigData });

    saveApihitStats({
      supplierApihit,
      sourceApihit,
      hitLimitValue,
      setGetAllConfigData,
    });
    setApiHitShow(false);
    getAllConfig({ setGetAllConfigData });
    // window.location.reload(false);
  };

  // ___________________________________DELETE SOURCE___________________________________

  const DeleteSource = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteSource?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        GetAllSources();
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SOURCE___________________________________

  // ___________________________________DELETE SUPPLIER API___________________________________

  const DeleteSupplier = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteApi?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        GetSupplierAPI();
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SUPPLIER API___________________________________

  // ___________________________________DELETE config ___________________________________

  const DeleteConfig = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteSupplierConfig?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getAllConfig({ setGetAllConfigData });
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SUPPLIER API___________________________________

  // _____________________________________flight autobooking __________________________________________

  // _____________________________________flight autobooking __________________________________________

  // _______________________________function of all the component_______________________________

  useEffect(() => {
    GetAllMeta();
    GetAllAPI();
    GetAllSource();
    GetAllSources();
    GetSupplierAPI();
    getApihitStats({ setHitStatus });
    getAllConfig({ setGetAllConfigData });
    getAllPreferAirline({ setPreferAirline });
  }, [updatedMetaState]);
  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */
  const getSource = (e) => {
    setGetSourceValue(e.target.value);
  };
  const GetSupplier = (e) => {
    setGetSupplierValue(e.target.value);
  };

  if (getRangeList.msg === "Server Error From Range") {
    return <div>Server Error</div>;
  }

  var GFSHandlerData = metaData;

  if (getSourceValue && !getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter((item) => item.source == getSourceValue);
    }
  } else if (!getSourceValue && getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter((item) => item.api == getSupplierValue);
    }
  } else if (getSourceValue && getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter(
        (item) => item.source == getSourceValue && item.api == getSupplierValue
      );
    }
  }
  if (isloading) {
    return (
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        <div className="container-fluid px-3">
          <Loading title="loading..." />
        </div>
      </div>
    );
  } else {
    const getSourceData = Object.keys(sources).length !== 0 ? sources.data : [];
    const getSuppliersData =
      Object.keys(suppliers).length !== 0 ? suppliers.data : [];

    return (
      <>
        {error === "None" ? (
          <>
            <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1 mb-3">
              <div className="container-fluid px-3">
                {ticketingStatus ||
                  (getRangeList.getDataLoading && (
                    <Loading title="loading..." />
                  ))}
                <div className="row">
                  {/* ____________________________________metaData____________________________________ */}

                  {activetab === 2 &&
                    (metaData != null && metaData.length != 0 ? (
                      <>
                        <div className="  col-12 mb-4 ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Handler
                            </h6>
                            <div className="w-100">
                              {metaData === null ? (
                                <>
                                  <Lottie
                                    options={defaultOptions}
                                    height={280}
                                    width={300}
                                  />
                                  <h6 className="title-lightthm p-3 m-0">
                                    {" "}
                                    Click on add button to add API
                                  </h6>
                                </>
                              ) : (
                                <div className="table-responsive lightthm-wrap">
                                  <table className=" table table-lightthm text-left">
                                    <thead className="bg-light">
                                      <tr>
                                        <th width={20}>S no.</th>
                                        <th>
                                          <Select
                                            label=""
                                            firstName="Source"
                                            optionValue={getSourceData}
                                            onChange={getSource}
                                            classstyle="form-control"
                                          />
                                        </th>
                                        <th>
                                          <Select
                                            label=""
                                            firstName="Supplier"
                                            optionValue={getSuppliersData}
                                            onChange={GetSupplier}
                                            classstyle="form-control"
                                          />
                                        </th>
                                        <th>Status</th>
                                        <th>Live</th>
                                        <th>Timeout</th>
                                        <th>Count</th>
                                        <th>Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {GFSHandlerData.map((item, i) => (
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>{item.source}</td>
                                          <td>{item.api}</td>
                                          <td>
                                            <div className="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="status"
                                                id="flexSwitchCheckDefault"
                                                value={state}
                                                onChange={(e) =>
                                                  handleSwitch(e, item)
                                                }
                                                checked={item.status}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            {" "}
                                            <div className="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="live"
                                                id="flexSwitchCheckDefault"
                                                value={state}
                                                onChange={(e) =>
                                                  handleSwitch(e, item)
                                                }
                                                checked={item.live}
                                              />
                                            </div>
                                          </td>
                                          <td
                                            className=" cursor-pointer"
                                            onClick={() => setIsTimeOut(item)}
                                          >
                                            {item.timeOut}
                                          </td>
                                          <td>{item.count}</td>

                                          <td>
                                            <div className="btni-sp">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip>Delete</Tooltip>
                                                }
                                              >
                                                <button
                                                  className="btn btn-sm btn-soft-danger"
                                                  onClick={() =>
                                                    DeleteMeta(item._id)
                                                  }
                                                >
                                                  <i className="fa-solid fa-trash"></i>
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>

                            <div className="text-right px-3 py-4">
                              <div className="btni-sp">
                                <button
                                  className="btn-submit btnver-2 success"
                                  onClick={() => HandleShow()}
                                >
                                  Add API{" "}
                                  <i className="fa-solid fa-plus icon-add"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="  col-12 mb-4 ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Google Flight Supplier Handle
                            </h6>
                            <Lottie
                              options={defaultOptions}
                              height={280}
                              width={300}
                            />
                            <h6 className="title-lightthm p-3 m-0">
                              {" "}
                              Click on add button to add API
                            </h6>
                            <div className="container-fluid px-3">
                              <div className="row text-right px-3 py-4">
                                <div className="btni-sp">
                                  <button
                                    className="btn-submit btnver-2 success"
                                    onClick={() => HandleShow()}
                                  >
                                    Add API{" "}
                                    <i className="fa-solid fa-plus icon-add"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {/* ____________________________________metaData____________________________________ */}

                  {/* ____________________________________config____________________________________ */}

                  {activetab === 3 &&
                    (getAllConfigData != null &&
                    getAllConfigData.length != 0 ? (
                      <>
                        <div className="  col-12 mb-4 ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Config Hit Limit
                            </h6>
                            <div className="w-100">
                              <div className="table-responsive lightthm-wrap">
                                <table className=" table table-lightthm text-left">
                                  <thead className="bg-light">
                                    <tr>
                                      <th width={20}>S no.</th>
                                      <th>supplier</th>
                                      <th>hitLimit</th>
                                      <th>source</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getAllConfigData.map((item, i) => (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.supplier}</td>
                                        <td>{item.hitLimit}</td>
                                        <td>{item.source}</td>
                                        <td>
                                          <div className="btni-sp">
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Delete</Tooltip>
                                              }
                                            >
                                              <button
                                                className="btn btn-sm btn-soft-danger"
                                                onClick={() =>
                                                  DeleteConfig(item._id)
                                                }
                                              >
                                                <i className="fa-solid fa-trash"></i>
                                              </button>
                                            </OverlayTrigger>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="text-right px-3 py-4">
                              <div className="btni-sp">
                                <button
                                  className="btn-submit btnver-2 success"
                                  onClick={() => handleApihit()}
                                >
                                  Add Config{" "}
                                  <i className="fa-solid fa-plus icon-add"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="  col-12 mb-4 ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Config Hit Limit
                            </h6>
                            <Lottie
                              options={defaultOptions}
                              height={280}
                              width={300}
                            />
                            <h6 className="title-lightthm p-3 m-0">
                              {" "}
                              Click on add button to add API
                            </h6>
                            <div className="container-fluid px-3">
                              <div className=" text-right px-3 py-4">
                                <div className="btni-sp">
                                  <button
                                    className="btn-submit btnver-2 success"
                                    onClick={() => handleApihit()}
                                  >
                                    Add Config{" "}
                                    <i className="fa-solid fa-plus icon-add"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {/* ____________________________________config____________________________________ */}

                  {/* ____________________________________sourceStatus____________________________________ */}

                  {activetab === 7 &&
                    (sourceStatus ? (
                      <>
                        {sources.data != null && sources.data.length != 0 ? (
                          <>
                            <div className="  col-12 mb-4 ">
                              <div className="metaBox position-relative d-block border-0 smtb">
                                <h6 className="title-lightthm p-3 m-0">
                                  All Sources
                                </h6>
                                <div className="w-100">
                                  <div className="table-responsive lightthm-wrap">
                                    <table className=" table table-lightthm text-left">
                                      <thead className="bg-light">
                                        <tr>
                                          <th width="20">Sr. No.</th>
                                          <th>Name</th>
                                          <th width="220">Deleted</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {sources.data.map((item, i) => (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                              <div className="btni-sp">
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip>Delete</Tooltip>
                                                  }
                                                >
                                                  <button
                                                    className="btn btn-sm btn-soft-danger"
                                                    onClick={() =>
                                                      DeleteSource(item._id)
                                                    }
                                                  >
                                                    <i className="fa-solid fa-trash"></i>
                                                  </button>
                                                </OverlayTrigger>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="text-right px-3 py-4">
                                  <div className="btni-sp">
                                    <button
                                      className="btn-submit btnver-2 success"
                                      onClick={() => OpenSources()}
                                    >
                                      Add Source{" "}
                                      <i className="fa-solid fa-plus icon-add"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="  col-12 mb-4 ">
                              <div className="metaBox position-relative d-block border-0 smtb">
                                <h6 className="title-lightthm p-3 m-0">
                                  All Sources
                                </h6>
                                <Lottie
                                  options={defaultOptions}
                                  height={280}
                                  width={300}
                                />
                                <h6 className="title-lightthm p-3 m-0">
                                  {" "}
                                  Click on add button to add API
                                </h6>
                                <div className="container-fluid px-3">
                                  <div className="text-right px-3 py-4">
                                    <div className="btni-sp">
                                      <button
                                        className="btn-submit btnver-2 success"
                                        onClick={() => OpenSources()}
                                      >
                                        Add Source{" "}
                                        <i className="fa-solid fa-plus icon-add"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>Please Wait for the result</>
                    ))}

                  {/* ____________________________________sourceStatus____________________________________ */}

                  {/* ____________________________________hitStatus____________________________________ */}

                  {activetab === 4 &&
                    (hitStatus != null && hitStatus.length != 0 ? (
                      <>
                        <div className="  col-12 mb-4 ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Search history
                            </h6>
                            <div className="w-100">
                              <div className="table-responsive lightthm-wrap">
                                <table className=" table table-lightthm text-left">
                                  <thead className="bg-light">
                                    <tr>
                                      <th width={20}>S no.</th>
                                      <th>successHit</th>
                                      <th>errorHit</th>
                                      <th>localHit</th>
                                      <th>supplier</th>
                                      <th>sourceMedia</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {hitStatus.map((item, i) => (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.successHit}</td>
                                        <td>{item.errorHit}</td>
                                        <td>{item.localHit}</td>
                                        <td>{item.supplier}</td>
                                        <td>{item.sourceMedia}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="  col-12 mb-4 ">
                          <h6 className="title-lightthm p-3 m-0">
                            Supplier Search history
                          </h6>
                        </div>
                      </>
                    ))}
                  {/* ------------------------Airline Fare Identifier------------ */}

                  {activetab === 5 && (
                    <FareIdentifier getSuppliersData={getSuppliersData} />
                  )}
                  {/* ____________________________________hitStatus____________________________________ */}

                  {/* ____________________________________SuppliersStatus____________________________________ */}

                  {activetab === 8 &&
                    (SuppliersStatus ? (
                      <>
                        {suppliers.data != null &&
                        suppliers.data.length != 0 ? (
                          <>
                            <div className="  col-12 mb-4 ">
                              <div className="metaBox position-relative d-block border-0 smtb">
                                <h6 className="title-lightthm p-3 m-0">
                                  Supplier (API)
                                </h6>
                                <div className="w-100">
                                  <div className="table-responsive lightthm-wrap">
                                    <table className=" table table-lightthm text-left">
                                      <thead className="bg-light">
                                        <tr>
                                          <th width="20">Sr. No.</th>
                                          <th>Name</th>
                                          <th width="220">Deleted</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {suppliers.data.map((item, i) => (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                              <div className="btni-sp">
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip>Delete</Tooltip>
                                                  }
                                                >
                                                  <button
                                                    className="btn btn-sm btn-soft-danger"
                                                    onClick={() =>
                                                      DeleteSupplier(item._id)
                                                    }
                                                  >
                                                    <i className="fa-solid fa-trash"></i>
                                                  </button>
                                                </OverlayTrigger>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="text-right px-3 py-4">
                                  <div className="btni-sp">
                                    <button
                                      className="btn-submit btnver-2 success"
                                      onClick={() => OpenAPIModal()}
                                    >
                                      Add API{" "}
                                      <i className="fa-solid fa-plus icon-add"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="  col-12 mb-4 ">
                              <div className="metaBox position-relative d-block border-0 smtb">
                                <h6 className="title-lightthm p-3 m-0">
                                  Supplier (API)
                                </h6>
                                <Lottie
                                  options={defaultOptions}
                                  height={280}
                                  width={300}
                                />
                                <h6 className="title-lightthm p-3 m-0">
                                  {" "}
                                  Click on add button to add API
                                </h6>
                                <div className="container-fluid px-3">
                                  <div className="text-right px-3 py-4">
                                    <div className="btni-sp">
                                      <button
                                        className="btn-submit btnver-2 success"
                                        onClick={() => OpenAPIModal()}
                                      >
                                        Add API{" "}
                                        <i className="fa-solid fa-plus icon-add"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>Please Wait for the result</>
                    ))}
                  {/* ____________________________________SuppliersStatus____________________________________ */}

                  {activetab === 1 && (
                    <div className="nospac mb-4 apitrc-dashboarddata">
                      <DashboardData allApi={allApi} />
                    </div>
                  )}

                  {activetab === 9 && (
                    <RangeList
                      data={
                        getRangeList.getData.data == null
                          ? []
                          : getRangeList.getData.data
                      }
                    />
                  )}
                  {activetab === 6 && (
                    <PreferedAirline
                      data={preferairline}
                      setTicketingStatus={setTicketingStatus}
                      setUpdatedMetaState={setUpdatedMetaState}
                      getSuppliersData={getSuppliersData}
                    />
                  )}
                  {activetab === 10 && <SiteTracking />}
                  {activetab === 11 && (
                    <BlockAirport
                      getSourceData={getSourceData}
                      getSuppliersData={getSuppliersData}
                    />
                  )}
                  {/* -------------------Portal Config--------------- */}
                  {activetab === 12 && <PortalConfig />}

                  {/* ---------------------IP Whitelist------------- */}
                  {activetab === 13 && <IPWhiltelist />}

                  {/* -------------------------Hold Ticket Holder------------------------------------------------- */}
                  {activetab === 14 && (
                    <HoldTicketHandler allSource={allSource} allApi={allApi} />
                  )}
                  {/* --------------------------Content visibility Management---------------------------- */}
                  {activetab === 15 && (
                    <ContentVisibleMgt allSource={allSource} allApi={allApi} />
                  )}
                  {/* --------------------------PAYMENT GATEWAY ---------------------------- */}
                  {activetab === 16 && <PaymentGateway allSource={allSource} />}
                  {/* --------------------------PAYMENT GATEWAY ---------------------------- */}
                  {activetab === 17 && (
                    <MetaSuppMap allSource={allSource} allSupplier={allApi} />
                  )}
                  {/* --------------------------PAYMENT GATEWAY ---------------------------- */}
                  {activetab === 18 && (
                    <GateSaveResponseMap
                      allSource={allSource}
                      allSupplier={allApi}
                    />
                  )}
                  {/* -------------------------Insurance HAndler ---------------------------- */}
                  {activetab === 19 && (
                    <InsuranceHandler
                      allSource={allSource}
                      allSupplier={allApi}
                    />
                  )}
                  {/* -------------------------Insurance Plan Benefits ---------------------------- */}
                  {activetab === 20 && <InsuranceBenefits />}
                  {/* -------------------------Insurance ---------------------------- */}
                  {activetab === 21 && <Insurance allSource={allSource} />}
                  {/* -------------------------SVG Icon Handler ---------------------------- */}
                  {activetab === 22 && <IconHandler allSource={allSource} />}
                </div>
              </div>

              {/* ------------------------------------google flight supplier handle ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic metadialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={HandleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add Meta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="row">
                      <div className="w-100">
                        <div className="input-classicsite mb-4">
                          <label className="form-label">Source</label>
                          <div className="input-group">
                            <select
                              className="form-select"
                              value={ApiName}
                              aria-label="Default select example"
                              onChange={(e) => setApiName(e.target.value)}
                            >
                              <option selected>select source</option>

                              {sources == null ||
                              sources === undefined ||
                              sources.data === undefined ||
                              sources.data == null ? (
                                <h3>No Record Found</h3>
                              ) : (
                                <>
                                  <h4>
                                    {sources.data.map((items, i) => (
                                      <>
                                        <p>{i}</p>
                                      </>
                                    ))}
                                  </h4>
                                  {sources.data.map((item) => (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="input-classicsite mb-4">
                          <label className="form-label">Api</label>

                          <div className="input-group ">
                            <select
                              className="form-select"
                              value={ApiData}
                              aria-label="Default select example"
                              onChange={(e) => setApiData(e.target.value)}
                            >
                              <option selected>select API</option>

                              {suppliers == null ||
                              suppliers === undefined ||
                              suppliers.data === undefined ||
                              suppliers.data == null ? (
                                <h3>No Record Found</h3>
                              ) : (
                                <>
                                  {suppliers.data.map((item) => (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <fieldset className="row mb-3">
                      <legend className="col-form-label col-12 pt-0 fw-bold">
                        Status
                      </legend>
                      <div className="col-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            id="gridRadios1"
                            value="true"
                            onClick={(e) => handleChangeValue(e)}
                          />
                          <label className="form-check-label" for="gridRadios1">
                            Active
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            id="gridRadios2"
                            value="false"
                            onClick={(e) => handleChangeValue(e)}
                          />
                          <label className="form-check-label" for="gridRadios2">
                            Inactive
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <button
                    type="submit"
                    className="btn btn btn-submit btnver-2"
                    onClick={() => saveMeta()}
                  >
                    submit
                  </button>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------google flight supplier handle ------------------------------------ */}

              {/* ------------------------------------Supplier config hit limit ------------------------------------ */}
              <HitApiModal
                handlecloseApihit={handlecloseApihit}
                apiHitShow={apiHitShow}
                allSource={allSource}
                ApiName={ApiName}
                allApi={allApi}
                sources={sources}
                suppliers={suppliers}
                setSourceApihit={setSourceApihit}
                setSupplierApihit={setSupplierApihit}
                supplierApihit={supplierApihit}
                sourceApihit={sourceApihit}
                setHitLimitValue={setHitLimitValue}
                hitLimitValue={hitLimitValue}
                onClick={() => saveHitApiData()}
              />
              {/* ------------------------------------Supplier config hit limit ------------------------------------ */}

              {/* ------------------------------------ALL sources ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic metadialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={sourcesModal}
                onHide={closeSources}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add Sources</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb-4">
                    <div className="input-classicsite">
                      <label className="form-label">
                        Enter the Source Name{" "}
                      </label>
                      <input
                        type={`text`}
                        className="form-control"
                        required
                        onChange={(e) => SourceName(e.target.value)}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <button
                    className="btn btn-submit btnver-2"
                    disabled={allsourceModalButton}
                    onClick={() => saveSourceName()}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------ALL sources ------------------------------------ */}

              {/* ------------------------------------SAVE API ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic metadialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={APIModal}
                onHide={closeAPIModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb-4">
                    <div className="input-classicsite">
                      <label className="form-label">Enter the API Name </label>
                      <input
                        type={`text`}
                        className="form-control"
                        required
                        onChange={(e) => getapitextValue(e.target.value)}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <button
                    className="btn btn btn-submit btnver-2"
                    disabled={supplierAPIButton}
                    onClick={() => saveAPIValue()}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------Time Out modal ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic metadialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={istimeout ? true : false}
                onHide={() => {
                  setIsTimeOut(false);
                  setIsTimeOut("");
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">
                    Update Timeout
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form className="mb-4">
                    <div className="input-classicsite">
                      <label className="form-label">Timeout (In Seconds)</label>
                      <input
                        type={`number`}
                        className="form-control"
                        required
                        defaultValue={istimeout.timeOut}
                        onChange={(e) => setTimeOut(e.target.value)}
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <button
                    className="btn btn btn-submit btnver-2"
                    type="sumbit"
                    onClick={handleTimeOut}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        ) : (
          <>
            <div className="container ">
              <h4 className="error-class">{error}</h4>
            </div>
          </>
        )}
      </>
    );
  }
};

export default ApiTracking;
