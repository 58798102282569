import React from "react";
import Login from "./Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./Views/Dashboard";
import Apitracking from "./Views/ApiTracking";
import AutoUser from "./Views/AutoUser";
import BookingList from "./Views/BookingList";
import User from "./Views/Users";
import PaymentDetail from "./Views/Payment/PaymentDetail";
import Googlebooking from "./Views/Googlebooking";
import Faremanage from "./Views/Faremanage";
import MainDashboard from "./Views/MainDashboard";
import PaymentSwitcher from "./Views/PaymentSwitcher";
import Gateway from "./Views/Gateway";
import Payu from "./Views/Payu";
import CcAvenue from "./Views/Cc-avenue";
import SupplierResponse from "./Views/SupplierResponse";
import MarkUpDetails from "./Views/MarkUpDetails";
import axios from "axios";
import { travel_url } from "./Host";
import { useEffect } from "react";
import { VeryfyToken } from "./Apis/Veryfy/VeryfyToken";
import CachePriceFeed from "./Views/CachePriceFeed/CachePriceFeed";
import GooglebookingDetails from "./Views/GooglebookingDetails";
import Customize_Email from "./Views/Customize_Email/Customize_Email";
import DashboardBar from "./Molecule/Dashboard/DashboardSideBar";
import { Cachemanagement } from "./Views/CacheManagment";
import { SupPreAirline } from "./Views/SupplierPreferredAirline";
import FareRule from "./Views/TravoFareRule/FareRule";
import ManagePromo from "./Views/ManagePromo";
import PageNotFound from "./Views/PageNotFound";
import FlightCuponMgt from "./Views/CouponManagement/FlightCuponMgt";
import HotelCuponMgt from "./Views/CouponManagement/HotelCuponMgt";
import Retrieve from "./Views/Retrieve/Retrieve";
import PaymentService from "./Views/Payment/PaymentService";
import HdfcCacheMgmt from "./Views/CacheManagment/HdfcSessionMgmt/HdfcCacheMgmt";
import InfluencerCoupon from "./Views/CouponManagement/InfluencerCoupon";

const Main = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const roles = localStorage.getItem("role");

  const token = localStorage.getItem("token");

  useEffect(() => {
    VeryfyToken({
      token,
      navigate,
    });
  }, []);
  return (
    <>
      {location.pathname === "/login" || location.pathname === "/" ? (
        ""
      ) : (
        <DashboardBar />
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <GuardedRoute path='/Dashboard' element={<MainDashboard />} auth={isAutheticated} /> */}
        <Route path="/Login" element={<Login />} />
        <Route path="/Dashboard" element={<MainDashboard />} />
        <Route path="/booking-list" element={<BookingList />} />
        <Route path="/booking-list/:id" element={<BookingList />} />
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/auto-booking" element={<AutoUser />} />
        )}
        <Route path="/google-flight-booking" element={<Googlebooking />} />
        <Route path="/google-flight-booking/:id" element={<Googlebooking />} />
        {/* <Route
        path="/google-flight-booking/:id"
        element={<GooglebookingDetails />}
      /> */}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/cache-price-feed" element={<CachePriceFeed />} />
        )}
        <Route path="/payment-switcher" element={<PaymentSwitcher />} />
        <Route path="/gateway" element={<Gateway />} />
        <Route path="/payu" element={<Payu />} />
        <Route path="/cc-avenue" element={<CcAvenue />} />
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/supplier-response" element={<SupplierResponse />} />
        )}
        {/* <Route path="/fare-management" element={<Faremanage />} /> */}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/markup-details" element={<MarkUpDetails />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/cache-management" element={<Cachemanagement />} />
        )}
        {(roles === "ADMIN" ||
          roles === "DEVELOPER" ||
          roles === "MANAGER") && (
          <Route
            path="/supplier-preferred-airline"
            element={<SupPreAirline />}
          />
        )}
        {/* <Route
          path="/google-flight-booking/customize/email-send"
          element={<Customize_Email />}
        /> */}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/supplier-response/:id" element={<SupplierResponse />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/users" element={<User />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/manage-promo" element={<ManagePromo />} />
        )}
        {roles === "DEVELOPER" && (
          <Route path="/api-tracking" element={<Apitracking />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/flight-auto-booking" element={<Dashboard />} />
        )}

        {(roles === "ADMIN" ||
          roles === "DEVELOPER" ||
          roles === "MANAGER") && (
          <Route path="/trav-fare-rule/:id" element={<FareRule />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/payment" element={<PaymentDetail />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/payment-serivce" element={<PaymentService />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route
            path="/payment-serivce/page/:id"
            element={<PaymentService />}
          />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/payment/:id" element={<PaymentDetail />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route
            path="/coupon-managment/flights"
            element={<FlightCuponMgt />}
          />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/coupon-managment/hotels" element={<HotelCuponMgt />} />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route
            path="/coupon-managment/influencer"
            element={<InfluencerCoupon />}
          />
        )}
        <Route path="/retrieve" element={<Retrieve />} />
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route
            path="/payment-serivce/page/:id"
            element={<PaymentService />}
          />
        )}
        {(roles === "ADMIN" || roles === "DEVELOPER") && (
          <Route path="/hdfc-session" element={<HdfcCacheMgmt />} />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default Main;
