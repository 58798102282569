import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { addInfluencerCouponApi } from "../../../Services/Api/InfluencerCouponAPi";
import Swal from "sweetalert2";

const AddInfluencerModal = ({ influencerListObj, handleClose, fetchData }) => {
  const { influencersList, availCouponList } = influencerListObj;
  const [loading, setLoading] = useState(false);
  const [inputField, setInputField] = useState({
    influencerName: "",
    emailId: "",
    couponCode: "",
    rewardPointPerCouponUse: "",
    status: "",
  });
  const {
    influencerName,
    emailId,
    couponCode,
    rewardPointPerCouponUse,
    status,
    currency,
    rewardType,
  } = inputField;

  const handleChange = (e) => {
    let { value, name } = e.target;
    setInputField({ ...inputField, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await addInfluencerCouponApi(inputField);
      if (data.httpStatus === "OK") {
        handleClose();
        fetchData();
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
      console.log({ data });
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (influencerName) {
      const findMail = influencersList.find(
        (it) => it.name === influencerName
      ).email;
      setInputField({
        ...inputField,
        emailId: findMail,
      });
    }
  }, [influencerName]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="couponCode"
              label="Coupon Code"
              onChange={handleChange}
              optionValue={availCouponList}
              firstName={"Select"}
              required={true}
              classstyle="form-control"
              value={couponCode}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="influencerName"
              label="Influencer Name"
              onChange={handleChange}
              optionValue={influencersList}
              firstName={"Select"}
              required={true}
              classstyle="form-control"
              value={influencerName}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="emailId"
              placeholder="Influencer Email ID"
              label="Email Id"
              onChange={handleChange}
              required={true}
              classstyle="form-control"
              value={emailId}
              disabled={true}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="rewardPointPerCouponUse"
              placeholder="Enter Reward Point Per Coupon Use"
              label="Reward Amount"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={rewardPointPerCouponUse}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="rewardType"
              label="Reward Type"
              onChange={handleChange}
              optionValue={[
                { id: 1, name: "Flat" },
                { id: 2, name: "Percent" },
              ]}
              firstName={"Select"}
              required={true}
              classstyle="form-control mt-4"
              value={rewardType}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="currency"
              placeholder="Enter currency code"
              label="Currency"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={currency}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="status"
              label="Status"
              onChange={handleChange}
              optionValue={[
                { id: 1, name: "Active" },
                { id: 2, name: "Inactive" },
              ]}
              firstName={"Select"}
              required={true}
              classstyle="form-control mt-4"
              value={status}
              // disabled={isEdit ? true : false}
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title={loading ? "Please Wait..." : "Submit"}
              disabled={loading}
              color="btn btn-submit btnver-2  btn-md "
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddInfluencerModal;
