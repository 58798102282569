import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../../CustomComponent/Table";

const ShowInfluencerDataTable = ({ data, onEditClick }) => {
  return (
    <div className="">
      <div className="w-100">
        <div className="table-responsive lightthm-wrap  border-top-0">
          <table className="table table-lightthm text-left">
            <thead className="bg-light">
              <tr>
                <th>S.No.</th>
                <th>Coupon</th>
                <th>Name</th>
                <th>Email ID</th>
                <th>Reward Amount</th>
                <th>Reward Type</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, i) => (
                  <Table
                    i={i}
                    data={[
                      i + 1,
                      item.couponCode,
                      item.influencerName,
                      item.emailId,
                      item.rewardPointPerCouponUse,
                      item.rewardType,
                      item.currency,
                      item.status,
                      <div className="btni-sp">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <button
                            className="btn btn-sm btn-soft-success"
                            onClick={() => onEditClick(item)}
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        </OverlayTrigger>
                      </div>,
                    ]}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={9} style={{ textAlign: "center" }}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowInfluencerDataTable;
