import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHdfceSessionApi } from "../../../Services/Api/HdfcSessionMgmt";
import Loading from "../../../CustomComponent/Loading";
import { GetAllSources } from "../../../Apis/FlightSource/GetAllSources";
import Modals from "../../../CustomComponent/Modals";
import AddHdfcSession from "../../../Molecule/CacheMamagement/HdfcSessionMgmt/Modal/AddHdfcSession";
import HdfcFilterSection from "../../../Molecule/CacheMamagement/HdfcSessionMgmt/HdfcFilterSection";
import HdfcSessionTable from "../../../Molecule/CacheMamagement/HdfcSessionMgmt/HdfcSessionTable";
import UpdateHdfcSession from "../../../Molecule/CacheMamagement/HdfcSessionMgmt/Modal/UpdateHdfcSession";
const HdfcCacheMgmt = () => {
  const query = useParams();
  const [open, setopen] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState("");
  const [filterObj, setFilterObj] = useState({
    source: "",
    siteId: "",
    currency: "",
    product: "",
  });
  const [getSource, setGetSource] = useState({});
  const fetchData = async () => {
    try {
      const { data } = await getHdfceSessionApi(filterObj, query.id, 10);
      setSessionData(data.data ? data.data : []);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    GetAllSources({
      state: setGetSource,
      state2: () => {},
      error: () => {},
    });
  }, []);

  return (
    <div>
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        {loading && <Loading title="loading..." />}
        <HdfcFilterSection
          fetchData={fetchData}
          filterObj={filterObj}
          setFilterObj={setFilterObj}
          allSource={getSource}
        />
        <div className="text-right px-3 py-4">
          <button
            className="btn-submit btnver-2 success"
            onClick={() => {
              setopen(true);
              setEditData("");
            }}
          >
            Add
          </button>
        </div>
        <HdfcSessionTable
          data={sessionData}
          setEditData={setEditData}
          setopen={setopen}
        />
      </div>

      <Modals
        show={open}
        backdrop={false}
        HandleClose={() => setopen(false)}
        ModalBody={
          editData ? (
            <UpdateHdfcSession
              fetchData={fetchData}
              allSource={getSource}
              setShowModal={setopen}
              editData={editData}
            />
          ) : (
            <AddHdfcSession
              fetchData={fetchData}
              allSource={getSource}
              setShowModal={setopen}
            />
          )
        }
        title={(editData ? "Update" : "Add") + " HDFC Session"}
      />
    </div>
  );
};

export default HdfcCacheMgmt;
