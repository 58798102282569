import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import { allProducts } from "../../Apitracking/PaymentGateway/Modal/AddPaymentGateway";
import Collapsible from "react-collapsible";
import Button from "../../../CustomComponent/Button";

const HdfcFilterSection = ({
  allSource,
  fetchData,
  setFilterObj,
  filterObj,
}) => {
  const { source, siteId, currency, product, session } = filterObj;
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFilterObj({ ...filterObj, [name]: value.trimStart() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };
  return (
    <div className="">
      <div className="container-fluid px-3">
        <Collapsible
          contentInnerClassName="collapsible-custom mt-3"
          triggerClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          triggerOpenedClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          trigger={"Filter"}
        >
          <div className="filter-css">
            <form>
              <Row>
                <Col xs={12} md={6} xl={3}>
                  <Select
                    name="source"
                    placeholder=""
                    label="Source"
                    onChange={handleChange}
                    optionValue={allSource.data || []}
                    firstName={"Select"}
                    classstyle="form-control"
                    value={source}
                  />
                </Col>
                <Col xs={12} md={6} xl={3}>
                  <Inputs
                    type="number"
                    name="siteId"
                    placeholder="Enter the Site ID"
                    label="Site ID"
                    onChange={handleChange}
                    classstyle="form-control"
                    value={siteId}
                  />
                </Col>
                <Col xs={12} md={6} xl={3}>
                  <Select
                    name="product"
                    placeholder=""
                    label="Product"
                    onChange={handleChange}
                    optionValue={allProducts}
                    classstyle="form-control"
                    value={product}
                  />
                </Col>
                <Col xs={12} md={6} xl={3}>
                  <Inputs
                    type="text"
                    name="currency"
                    placeholder="Enter the currency code"
                    label="Currency"
                    onChange={handleChange}
                    classstyle="form-control"
                    value={currency}
                  />
                </Col>
                <Col xs={12} md={6} xl={3}>
                  <button
                    className="btn btn-submit btnver-2 w-100 my-4 py-2"
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

export default HdfcFilterSection;
