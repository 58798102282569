import React, { useEffect, useState } from "react";
import Screen from "../../CustomComponent/Screen";
import Button from "../../CustomComponent/Button";
import Modals from "../../CustomComponent/Modals";
import AddInfluencerModal from "../../Molecule/CouponManagement/InfluencerCouponComp/AddInfluencerModal";
import { getInfluencerCouponsListApi } from "../../Services/Api/InfluencerCouponAPi";
import ShowInfluencerDataTable from "../../Molecule/CouponManagement/InfluencerCouponComp/ShowInfluencerDataTable";
import Loading from "../../CustomComponent/Loading";
import UpdateInfluencerModal from "../../Molecule/CouponManagement/InfluencerCouponComp/UpdateInfluencerModal";

const InfluencerCoupon = () => {
  const [loading, setloading] = useState(true);
  const [influencerListObj, setInfluencerListObj] = useState({
    influencersList: [],
    availCouponList: [],
    influencerData: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [isEditData, setIsEditData] = useState(null);
  function handleClose() {
    setShowModal(false);
  }
  const fetchData = async () => {
    try {
      const { data } = await getInfluencerCouponsListApi();
      if (data.httpStatus === "OK") {
        const { availableCoupon, influencers, assignedCoupon } = data.data;
        const newCouponRes =
          availableCoupon.length > 0
            ? availableCoupon.map((ele) => ({ name: ele }))
            : [];
        setInfluencerListObj({
          ...influencerListObj,
          influencersList: influencers,
          availCouponList: newCouponRes,
          influencerData: assignedCoupon,
        });
      }
    } catch (error) {}
    setloading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <Loading title="Loading..." />;
  }
  return (
    <div>
      <Screen>
        <div className="pb-3  d-flex justify-content-between">
          <Button
            title={
              <>
                <i className="fa-solid fa-plus icon-add"></i> Add
              </>
            }
            color="btn-submit btnver-2 success ms-auto"
            onClick={() => {
              setShowModal(true);
              setIsEditData(null);
            }}
          />
        </div>
        <ShowInfluencerDataTable
          data={influencerListObj.influencerData}
          setIsEditData={setIsEditData}
          onEditClick={(item) => {
            setShowModal(true);
            setIsEditData(item);
          }}
        />
      </Screen>
      {/* ------------------------Modal------------===================== */}
      <Modals
        show={showModal}
        HandleClose={handleClose}
        ModalBody={
          <>
            {isEditData ? (
              <UpdateInfluencerModal
                handleClose={handleClose}
                fetchData={fetchData}
                isEditData={isEditData}
              />
            ) : (
              <AddInfluencerModal
                influencerListObj={influencerListObj}
                handleClose={handleClose}
                fetchData={fetchData}
              />
            )}
          </>
        }
        title={(isEditData ? "Update" : "Add") + " Influencer Coupon"}
      />
    </div>
  );
};

export default InfluencerCoupon;
