import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";
import logo from "../../Image/logo.svg";
import smlogo from "../../Image/smlogo.png";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const SideBar = () => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [cuponopen, setCupnOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [cacheOpen, setCacheOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate();

  function logout() {
    localStorage.clear();
    navigate("/");
  }

  const roles = localStorage.getItem("role");
  return (
    <>
      {/* <div className="sideNavbTN" onClick={()=>{setShow(!show)}}>
        <MdOutlineMenu size={28} color={"#00000"} />
      </div> */}

      {/* <Offcanvas show={show} onHide={handleClose} className="offcanvas-sidenav"> */}

      <div className={"offcanvas-sidenav offcanvas offcanvas-start"}>
        <Offcanvas.Header>
          <Offcanvas.Title className="text-center">
            <img src={logo} width="130" className="notsidenvactive" />
            <img src={smlogo} width="32" className="sidenvactive" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        {roles === "DATA_ANALYTICS" ? (
          <Offcanvas.Body>
            <div className="d-flex h-100 flex-column">
              <div className="flex-grow-1">
                <NavLink
                  to="/Dashboard"
                  className="link"
                  activeclassname="active"
                >
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i className="fa-brands menu-icon fa-quinscape"></i>
                    <span className="titlenv"> &nbsp; Dashboard</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/google-flight-booking"
                  className="link"
                  activeclassname="active"
                >
                  {" "}
                  <div className="dashboard-item">
                    {" "}
                    <i className="fa fa-solid fa-google menu-icon "></i>
                    <span className="titlenv">
                      &nbsp; Google Flight Booking
                    </span>
                  </div>
                </NavLink>

                <button onClick={() => logout()} className="border-0 w-100">
                  {" "}
                  <div className="dashboard-item  text-left">
                    {" "}
                    <i className="fa-solid menu-icon fa-right-from-bracket"></i>
                    &nbsp; <span className="titlenv"> Logout</span>
                  </div>
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        ) : (
          <>
            <Offcanvas.Body>
              <div className="d-flex h-100 flex-column">
                <div className="flex-grow-1">
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Dashboard</Tooltip>}
                  >
                    <NavLink
                      to="/Dashboard"
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i className="fa fa-home menu-icon fa-quinscape"></i>
                        <span className="titlenv"> &nbsp; Dashboard</span>
                      </div>
                    </NavLink>
                  </OverlayTrigger>

                  {roles === "ADMIN" || roles === "DEVELOPER" ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Flight Auto Booking</Tooltip>}
                    >
                      <NavLink
                        to="/flight-auto-booking"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa-brands menu-icon fa-quinscape"></i>
                          <span className="titlenv">
                            {" "}
                            &nbsp; Flight Auto Booking
                          </span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}

                  {roles === "DEVELOPER" && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>API Tracking</Tooltip>}
                    >
                      <NavLink
                        to="/api-tracking"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa-solid menu-icon fa-code"></i>
                          <span className="titlenv">&nbsp; API Tracking</span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}

                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Auto-Booking</Tooltip>}
                    >
                      <NavLink
                        to="/auto-booking"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa-solid menu-icon fa-users-rectangle"></i>
                          <span className="titlenv">&nbsp; Auto-Booking</span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}

                  {/* <NavLink to="/supplier" className="link" activeclassname="active"> <div className="dashboard-item"> <i className="fa-solid menu-icon fa-users-rectangle"></i>&nbsp; Supplier Handler</div></NavLink> */}

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Booking List</Tooltip>}
                  >
                    <NavLink
                      to="/booking-list"
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i className="fa fa-list menu-icon fa-users-rectangle"></i>
                        <span className="titlenv">&nbsp; Booking List</span>
                      </div>
                    </NavLink>
                  </OverlayTrigger>

                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Supplier Response</Tooltip>}
                    >
                      <NavLink
                        to="/supplier-response"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa-solid menu-icon fa-users-rectangle"></i>
                          <span className="titlenv">
                            &nbsp; Supplier Response
                          </span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}

                  {/* {roles === "ADMIN" || roles === "DEVELOPER" ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip> Payment Detail</Tooltip>}
                    >
                      <NavLink
                        to="/payment"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-credit-card menu-icon fa-users-rectangle"></i>
                          <span className="titlenv">&nbsp; Payment Detail</span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )} */}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <div className="collapsemenu-nv position-relative">
                      <div
                        className={
                          "dashboard-item menubox menuboxitem" +
                          (paymentOpen ? " active" : "")
                        }
                        onClick={() => setPaymentOpen(!paymentOpen)}
                        aria-controls="example-collapse-text"
                        aria-expanded={paymentOpen}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <i className="fa fa-credit-card menu-icon fa-users-rectangle"></i>
                            <span className="titlenv">
                              &nbsp; Payment Details
                            </span>
                          </div>
                          <span className="titlenv">
                            <i
                              className={
                                "fa mr-0 fa-angle-" +
                                (paymentOpen ? "up" : "down")
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>

                      <Collapse in={paymentOpen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/payment">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Payment
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      <Collapse in={paymentOpen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/payment-serivce">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Payment Service
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      {/* "/payment-switcher" element={<PaymentSwitcher />} />
          <Route path="/gateway" element={<Gateway />} />
          <Route path="/payu" element={<Payu />} />
          <Route path="/cc-avenue */}
                    </div>
                  )}
                  {roles === "ADMIN" || roles === "DEVELOPER" ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Users</Tooltip>}
                    >
                      <NavLink
                        to="/users"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-users menu-icon fa-users-rectangle"></i>
                          <span className="titlenv"> &nbsp; Users</span>
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Google Flight Booking</Tooltip>}
                  >
                    <NavLink
                      to="/google-flight-booking"
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i className="fa fa-solid fa-google menu-icon "></i>
                        <span className="titlenv">
                          &nbsp; Google Flight Booking
                        </span>
                      </div>
                    </NavLink>
                  </OverlayTrigger>

                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <div className="collapsemenu-nv position-relative">
                      <div
                        className={
                          "dashboard-item menubox menuboxitem" +
                          (open ? " active" : "")
                        }
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <i
                              className="fa fa-toggle-on menu-icon"
                              aria-hidden="true"
                            ></i>
                            <span className="titlenv">
                              &nbsp; Switcher Menu
                            </span>
                          </div>
                          <span className="titlenv">
                            <i
                              className={
                                "fa mr-0 fa-angle-" + (open ? "up" : "down")
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>

                      <Collapse in={open}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/payment-switcher">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Payment switcher
                            </div>
                          </NavLink>

                          <NavLink to="/gateway">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Gatway
                            </div>
                          </NavLink>
                          <NavLink to="/payu">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Payu
                            </div>
                          </NavLink>
                          <NavLink to="/cc-avenue">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; CcAvenue
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>

                      {/* "/payment-switcher" element={<PaymentSwitcher />} />
          <Route path="/gateway" element={<Gateway />} />
          <Route path="/payu" element={<Payu />} />
          <Route path="/cc-avenue */}
                    </div>
                  )}

                  {/* <OverlayTrigger placement="right" overlay={<Tooltip>Fare
                  Management</Tooltip>}>
                  <NavLink to="/fare-management" className="link" activeclassname="active">
                    {" "}
                    <div className="dashboard-item">
                      {" "}
                      <i className="fa fa-tasks menu-icon "></i>
                      <span className="titlenv">&nbsp; Fare
                        Management</span>{" "}
                    </div>
                  </NavLink>
                </OverlayTrigger> */}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Markup Details</Tooltip>}
                    >
                      <NavLink
                        to="/markup-details"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-info-circle menu-icon "></i>
                          <span className="titlenv">
                            &nbsp; Markup Details
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Cache Price Feed</Tooltip>}
                    >
                      <NavLink
                        to="/cache-price-feed"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-solid fa-ticket menu-icon "></i>
                          <span className="titlenv">
                            &nbsp; Cache Price Feed
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <div className="collapsemenu-nv position-relative">
                      <div
                        className={
                          "dashboard-item menubox menuboxitem" +
                          (cacheOpen ? " active" : "")
                        }
                        onClick={() => setCacheOpen(!cacheOpen)}
                        aria-controls="example-collapse-text"
                        aria-expanded={cacheOpen}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <i className="fa fa-solid fa-ticket menu-icon "></i>
                            <span className="titlenv">
                              &nbsp; Cache Management
                            </span>
                          </div>
                          <span className="titlenv">
                            <i
                              className={
                                "fa mr-0 fa-angle-" +
                                (cacheOpen ? "up" : "down")
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>

                      <Collapse in={cacheOpen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/cache-management">
                            <div className="dashboard-item">
                              {" "}
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              <span className="titlenv">
                                &nbsp; Cache Management
                              </span>{" "}
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      <Collapse in={cacheOpen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/hdfc-session">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; HDFC Session
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {/* {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Cache Cache Management</Tooltip>}
                    >
                      <NavLink
                        to="/cache-management"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-solid fa-ticket menu-icon "></i>
                          <span className="titlenv">
                            &nbsp; Cache Management
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )} */}
                  {(roles === "ADMIN" ||
                    roles === "DEVELOPER" ||
                    roles === "MANAGER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Supplier Preferred Airline</Tooltip>}
                    >
                      <NavLink
                        to="/supplier-preferred-airline"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-solid fa-ticket menu-icon "></i>
                          <span className="titlenv">
                            &nbsp; Sup-Pre Airline
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}
                  {(roles === "ADMIN" ||
                    roles === "DEVELOPER" ||
                    roles === "MANAGER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Travomint Fare Rule</Tooltip>}
                    >
                      <NavLink
                        to="/trav-fare-rule/page=1"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-solid fa-ticket menu-icon "></i>
                          <span className="titlenv">
                            &nbsp; Trav-fare rule
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Manage Promo</Tooltip>}
                    >
                      <NavLink
                        to="/manage-promo"
                        className="link"
                        activeclassname="active"
                      >
                        {" "}
                        <div className="dashboard-item">
                          {" "}
                          <i className="fa fa-solid fa-ticket menu-icon "></i>
                          <span className="titlenv">
                            &nbsp;Manage Promo
                          </span>{" "}
                        </div>
                      </NavLink>
                    </OverlayTrigger>
                  )}
                  {(roles === "ADMIN" || roles === "DEVELOPER") && (
                    <div className="collapsemenu-nv position-relative">
                      <div
                        className={
                          "dashboard-item menubox menuboxitem" +
                          (cuponopen ? " active" : "")
                        }
                        onClick={() => setCupnOpen(!cuponopen)}
                        aria-controls="example-collapse-text"
                        aria-expanded={cuponopen}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <i
                              className="fa-solid fa-ticket"
                              aria-hidden="true"
                            ></i>
                            <span className="titlenv">&nbsp; Coupon Mgmt</span>
                          </div>
                          <span className="titlenv">
                            <i
                              className={
                                "fa mr-0 fa-angle-" +
                                (cuponopen ? "up" : "down")
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>

                      <Collapse in={cuponopen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/coupon-managment/flights">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Flights
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      <Collapse in={cuponopen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/coupon-managment/hotels">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Hotels
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      <Collapse in={cuponopen}>
                        <div className="gsub-menu menuboxitem-content my-1">
                          <NavLink to="/coupon-managment/influencer">
                            <div className="dashboard-item">
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Influencer Coupon
                            </div>
                          </NavLink>
                        </div>
                      </Collapse>
                      {/* "/payment-switcher" element={<PaymentSwitcher />} />
          <Route path="/gateway" element={<Gateway />} />
          <Route path="/payu" element={<Payu />} />
          <Route path="/cc-avenue */}
                    </div>
                  )}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Retrieve </Tooltip>}
                  >
                    <NavLink
                      to="/retrieve"
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <div className="dashboard-item">
                        {" "}
                        <i className="fa fa-solid fa-ticket menu-icon "></i>
                        <span className="titlenv">&nbsp;Retrieve</span>{" "}
                      </div>
                    </NavLink>
                  </OverlayTrigger>
                </div>
              </div>
            </Offcanvas.Body>

            <div className="logout-fxm">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Logout</Tooltip>}
              >
                <div
                  onClick={() => logout()}
                  className="border-0 w-100 cursor-pointer"
                >
                  <div className="dashboard-item  text-left">
                    <i className="fa-solid menu-icon fa-right-from-bracket"></i>
                    &nbsp; <span className="titlenv"> Logout</span>
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default SideBar;
