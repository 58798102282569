import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { travel_url } from "../../Host";
import { Link } from "react-router-dom";
import Loading from "../../CustomComponent/Loading";
import Modals from "../../CustomComponent/Modals";
import CheckDataModal from "./BookingListComponent/CheckDataModal";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { commonheader } from "../../utils/header";
import JSONPretty from "react-json-prettify";
import Swal from "sweetalert2";
// import Booking_List_Api from "../../CustomApi/Booking_List_Api.json";

const BookingLists = ({ filterObj }) => {
  const [status, setstatus] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [listTotal, setlistTotal] = useState([]);
  const [checkDataPNR, setCheckDataPNR] = useState("");
  const [isbdsId, setIsBdsId] = useState("");
  const [show, setShow] = useState();
  const [retrivedata, setRetriveData] = useState({});
  const pageblgid = useParams();
  const [cnclModal, setCnclModal] = useState(false);
  const [cnclObj, setCnclObj] = useState({});
  const [cnclPnr, setCnclPnr] = useState([]);
  const [cnclLoading, setCnclLoad] = useState(false);
  const filter = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "Post",
      headers: myHeaders,
      body: JSON.stringify(filterObj),
      redirect: "follow",
    };
    // setstatus(true);

    const booking_data = await fetch(
      `${travel_url}/filter?page=${pagination_st + 1}&size=20`,
      requestOptions
    );
    const booking_data_formet = await booking_data.json();

    if (booking_data_formet) {
      setlistTotal(booking_data_formet);
      setstatus(false);
    } else {
      setstatus(false);
    }
  };

  const freeAllbatch = [];
  for (let i = 0; i < listTotal.totalPages; i++) {
    freeAllbatch.push(i);
  }

  const [pagination_st, setPaginationst] = useState(0);

  function setPaginationLeft(e) {}

  function setPaginationRight(e) {}

  const closeModal = () => {
    setShowModal(false);
  };
  const isBDSBookingClick = (item) => {
    if ([29].includes(item.doneBooking.gds)) {
      setIsBdsId(item);
    }
  };
  const applyConfirmCancel = async (bookingId, iscnf) => {
    Swal.fire({
      title: `Do you want to ${iscnf ? "Confirm" : "Cancel"}?`,
      showDenyButton: true,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.get(
            `${travel_url}/traveloes/${
              iscnf ? "confirm" : "cancel"
            }?id=${bookingId}`,
            { headers: commonheader() }
          );
          if (data.data) {
            setIsBdsId("");
            filter();
            Swal.fire("Successfully Updated", "", "success");
          } else {
            Swal.fire(data.msg, "", "info");
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };
  const getRetriveData = async (bookingId) => {
    try {
      const { data } = await axios.get(
        `${travel_url}/traveloes/retrieve?id=${bookingId}`,
        {
          headers: commonheader(),
        }
      );
      if (data.data) {
        setRetriveData(data.data);
        setIsBdsId("");
        setShow(true);
      } else {
        setRetriveData({});
        Swal.fire(data.msg, "", "info");
      }
    } catch (err) {
      console.log("werere", err);
    }
  };

  function createCancelPNRParm() {
    let gds = "";
    let pnr = "";
    if (cnclObj) {
      if (cnclPnr.length > 1) {
        pnr = cnclPnr.join(",");
        gds =
          cnclObj.pnr.split("/")[0] == cnclPnr[0]
            ? cnclObj.doneBooking.gds
            : cnclObj.doneBooking.returnGds > 0
            ? cnclObj.doneBooking.returnGds
            : cnclObj.doneBooking.gds;

        gds =
          gds +
          "," +
          (cnclObj.pnr.split("/")[1] == cnclPnr[1]
            ? cnclObj.doneBooking.returnGds > 0
              ? cnclObj.doneBooking.returnGds
              : cnclObj.doneBooking.gds
            : cnclObj.doneBooking.gds);
      } else {
        pnr = cnclPnr[0];
        gds =
          cnclObj.pnr.split("/")[0] == pnr
            ? cnclObj.doneBooking.gds
            : cnclObj.doneBooking.returnGds > 0
            ? cnclObj.doneBooking.returnGds
            : cnclObj.doneBooking.gds;
      }

      return {
        pnr: pnr,
        gds: gds,
      };
    }
    return null;
  }

  // -----------------------------------------------Cancel PNR -----------------------------------------
  const handleCancelPnr = async () => {
    setCnclLoad(true);
    const url = `${travel_url}/gds/cancelHoldPNRMulti`;
    const head = { headers: commonheader() };
    const getPnrGds = createCancelPNRParm();
    try {
      const { data } = await axios.get(
        `${url}?refno=${getPnrGds.pnr}&gdsStr=${getPnrGds.gds}`,
        head
      );
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        timer: 2500,
        showConfirmButton: false,
      });
      data.httpStatus === "OK" && filter();
      setCnclModal(false);
    } catch (error) {}
    setCnclLoad(false);
  };
  useEffect(() => {
    filter();
    setstatus(true);
  }, [pageblgid.id, filterObj]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageblgid]);
  useEffect(() => {
    if (!cnclModal) {
      setCnclPnr([]);
      setCnclObj({});
    }
  }, [cnclModal]);
  return (
    <>
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        {status !== true ? (
          <div className="container-fluid px-3 mb-4  mt-3">
            <div className="metaBox position-relative d-block border-0 smtb">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive lightthm-wrap scroll-auto">
                    <table className="table table-lightthm text-left">
                      <thead className="bg-light">
                        <tr>
                          <th>Sr.No.</th>
                          <th>Booking Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Trip Type</th>
                          <th>PNR</th>
                          <th>Booking ID</th>
                          <th>Order ID</th>
                          <th>RB</th>
                          <th>CB</th>
                          <th>BRS</th>
                          <th>BDS</th>
                          <th>Agent Name</th>
                          <th>Source</th>
                          <th>Status</th>
                          <th>Payment Status</th>
                          <th>Cancel</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listTotal.content.length > 0 ? (
                          listTotal.content.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={`${
                                  [29].includes(item.doneBooking.gds) &&
                                  " cursor-pointer"
                                }`}
                                onClick={() => isBDSBookingClick(item)}
                              >
                                {i + 1}
                              </td>
                              <td>
                                {new Date(
                                  item.bookingDate
                                ).toLocaleDateString()}
                                <br />
                                {new Date(
                                  item.bookingDate
                                ).toLocaleTimeString()}
                              </td>
                              <td>
                                {" "}
                                <i className="fa-solid fa-plane-departure me-2 text-primary"></i>{" "}
                                {item.origin}
                              </td>
                              <td>
                                {" "}
                                <i className="fa-solid fa-plane-arrival me-2 text-primary"></i>{" "}
                                {item.destination}
                              </td>
                              <td>
                                {item.tripType == 1 ? (
                                  <b className="text-info">One Way</b>
                                ) : (
                                  <b className="text-primary">Two Way</b>
                                )}
                              </td>
                              <td>{item.pnr}</td>
                              <td>{item.bookingId}</td>
                              <td className="green-bold">
                                {item.orderId != null ? item.orderId : "---"}
                              </td>
                              <td className="bg-primary text-white text-center fw-bold">
                                {" "}
                                ₹ {item.receivedBooking.amount.toFixed(2, 0)}
                              </td>
                              <td className="bg-success text-white text-center fw-bold">
                                {" "}
                                ₹ {item.doneBooking.amount.toFixed(2, 0)}
                              </td>

                              <td>
                                {" "}
                                {item.tripType == 1
                                  ? item.receivedBooking.gds
                                  : `${item.receivedBooking.gds}/${item.receivedBooking.returnGds}`}
                              </td>
                              <td>
                                {item.tripType == 1
                                  ? item.doneBooking.gds
                                  : `${item.doneBooking.gds}/${item.doneBooking.returnGds}`}
                              </td>
                              <td>
                                {item != null && item.bookingBy != null
                                  ? item.bookingBy.name
                                  : "---"}
                              </td>
                              <td>
                                {item != null && item.source != null
                                  ? item.source
                                  : "---"}
                              </td>
                              <td>{item.status}</td>
                              <td>
                                {item.paymentStatus ? item.paymentStatus : ""}
                              </td>
                              <td>
                                {item.status.includes("HOLD") &&
                                item.paymentStatus &&
                                item.paymentStatus.toLowerCase() !==
                                  "success" ? (
                                  <div className="btni-sp">
                                    <button
                                      onClick={() => {
                                        item.tripType === 1 &&
                                          setCnclPnr([item.pnr]);
                                        setCnclObj(item);
                                        setCnclModal(true);
                                      }}
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={15} className="text-center">
                              No items found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Modals
                show={showModal}
                HandleClose={closeModal}
                ModalBody={<CheckDataModal data={checkDataPNR} />}
                title="Booking"
              />
              <div className="row">
                <div>
                  {freeAllbatch.length > 0 ? (
                    <div className="col-12 pt-3 pagination-footer">
                      <nav className="text-center ">
                        <ul className="pagination justify-content-end blog_bxrowpagi flex-wrap mb-0">
                          <li
                            className={
                              "nv page-item itemnv-1" +
                              (pagination_st == 0 ? " disabled" : "") +
                              (freeAllbatch.length == 1 ? " d-none" : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/booking-list/page=1"}
                              onClick={() => {
                                setPaginationst(0);
                              }}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </Link>
                          </li>
                          <li
                            className={
                              "nv page-item itemnv-2" +
                              (pagination_st == 0 ? " disabled" : "") +
                              (freeAllbatch.length == 1 ? " d-none" : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/booking-list/page=" + pagination_st}
                              onClick={(e) => {
                                setPaginationLeft(e);
                              }}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">‹</span>
                            </Link>
                          </li>

                          {new Array(freeAllbatch.length - 1)
                            .fill()
                            .map((_, index) => (
                              <li
                                key={index}
                                className={
                                  "page-item dots" +
                                  (pagination_st == index ? " active" : "") +
                                  (pagination_st == index ? " activedot" : "") +
                                  (pagination_st - 1 == index
                                    ? " activedot"
                                    : "") +
                                  (pagination_st + 1 == index
                                    ? " activedot"
                                    : "") +
                                  (pagination_st - 2 == index
                                    ? " activedot"
                                    : "") +
                                  (pagination_st + 2 == index
                                    ? " activedot"
                                    : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={"/booking-list/page=" + (index + 1)}
                                  onClick={() => {
                                    setPaginationst(index);
                                  }}
                                >
                                  {index + 1}
                                </Link>
                              </li>
                            ))}
                          {/* <li
                            className={
                              "page-item dots" +
                              (pagination_st == freeAllbatch.length - 1
                                ? " active"
                                : "") +
                              (pagination_st == freeAllbatch.length - 1
                                ? " activedot"
                                : "") +
                              (pagination_st - 1 == freeAllbatch.length - 1
                                ? " activedot"
                                : "") +
                              (pagination_st + 1 == freeAllbatch.length - 1
                                ? " activedot"
                                : "") +
                              (pagination_st - 2 == freeAllbatch.length - 1
                                ? " activedot"
                                : "") +
                              (pagination_st + 2 == freeAllbatch.length - 1
                                ? " activedot"
                                : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/booking-list/page=" + freeAllbatch.length}
                              onClick={() => {
                                setPaginationst(freeAllbatch.length - 1);
                              }}
                            >
                              {freeAllbatch.length}
                            </Link>
                          </li> */}
                          <li className="w-100 d-block d-lg-none"></li>
                          <li
                            className={
                              "nv page-item itemnv-3" +
                              (pagination_st == freeAllbatch.length - 1
                                ? " disabled"
                                : "") +
                              (freeAllbatch.length == 1 ? " d-none" : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/booking-list/page=" + (pagination_st + 2)}
                              onClick={(e) => {
                                setPaginationRight(e);
                              }}
                              aria-label="Next"
                            >
                              <span aria-hidden="true">›</span>
                            </Link>
                          </li>
                          <li
                            className={
                              "nv page-item itemnv-4" +
                              (pagination_st == freeAllbatch.length - 1
                                ? " disabled"
                                : "") +
                              (freeAllbatch.length == 1 ? " d-none" : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/booking-list/page=" + freeAllbatch.length}
                              onClick={() => {
                                setPaginationst(freeAllbatch.length - 1);
                              }}
                              aria-label="Next"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="blog_bxrowpagi_empty"></div>
          </div>
        ) : (
          <Loading title="loading..." />
          // <div>
          //   <div className="loader-div">
          //     {/* <img src={loadergif} className="loadergif" /> */}
          //     <div className="loader"></div>
          //   </div>
          // </div>
        )}
      </div>
      <Modals
        show={isbdsId ? true : false}
        HandleClose={() => {
          setIsBdsId("");
        }}
        mclass="bdsList"
        ModalBody={
          <div className=" d-flex mb-4 justify-content-center gap-4">
            <Button
              variant="success"
              style={{ width: "100px" }}
              onClick={() => applyConfirmCancel(isbdsId.orderId, true)}
            >
              Confirm
            </Button>{" "}
            <Button
              variant="danger"
              style={{ width: "100px" }}
              onClick={() => applyConfirmCancel(isbdsId.orderId, false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="info"
              style={{ width: "100px", color: "white" }}
              onClick={() => getRetriveData(isbdsId.orderId)}
            >
              Retrieve
            </Button>{" "}
          </div>
        }
        title={"Update"}
      />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        className="modalbooknow-classic"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body className="reqpre">
          <p className="black">
            <JSONPretty json={retrivedata} />
          </p>
        </Modal.Body>
        <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
          <button
            className="btn btn btn-submit btnver-2"
            onClick={() => setShow(false)}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
      <Modals
        show={cnclModal}
        HandleClose={() => setCnclModal(false)}
        mclass="bdsList"
        ModalBody={
          <>
            <h4 className=" text-center">Do you want cancel your booking?</h4>
            {cnclObj && cnclObj.tripType === 2 && (
              <div>
                <p>Please select your PNR</p>
                <div className="text-center mt-2">
                  {cnclObj.pnr &&
                    [...new Set(cnclObj.pnr.split("/"))].map((item, i) => {
                      const isCnclPNR = cnclObj.status.split("/");
                      return (
                        item && (
                          <Form.Check
                            inline
                            label={item}
                            name={`group${i}`}
                            type="checkbox"
                            id={`group${i}`}
                            value={item}
                            disabled={isCnclPNR[i] === "Cancelled"}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              if (!["null", "X"].includes(value)) {
                                checked
                                  ? setCnclPnr([...cnclPnr, value])
                                  : setCnclPnr(
                                      cnclPnr.filter((it) => it !== value)
                                    );
                              } else {
                                Swal.fire({
                                  icon: "info",
                                  text: "It may be possible PNR has been generated, For further details please contact to tech team on urgent basis.",
                                  showConfirmButton: true,
                                  width: "700px",
                                });
                              }
                            }}
                          />
                        )
                      );
                    })}
                </div>
              </div>
            )}
            <div className=" d-flex my-4 justify-content-center gap-4">
              <Button
                variant="success"
                disabled={cnclLoading}
                style={{ width: "100px" }}
                onClick={(cnclPnr.length > 0 || cnclLoading) && handleCancelPnr}
                // onClick={() => applyConfirmCancel(isbdsId.orderId, true)}
              >
                {cnclLoading ? "Loading..." : "Yes"}
              </Button>{" "}
              <Button
                variant="danger"
                style={{ width: "100px" }}
                onClick={() => setCnclModal(false)}
              >
                No
              </Button>{" "}
            </div>
          </>
        }
        title={"Cancel"}
      />
    </>
  );
};

export default BookingLists;
