import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { allProducts } from "../../Apitracking/PaymentGateway/Modal/AddPaymentGateway";
const PaymentServiceFilter = ({
  allGatewayList,
  filterObj,
  setFilterObj,
  fetchData,
}) => {
  const { gateway, status, orderId, product } = filterObj;
  const handlerChange = (e) => {
    let { name, value } = e.target;
    setFilterObj({ ...filterObj, [name]: value.trimStart() });
  };
  return (
    <div>
      <div className="container-fluid px-3">
        <Collapsible
          contentInnerClassName="collapsible-custom mt-3"
          triggerClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          triggerOpenedClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          trigger={"Filter"}
        >
          <div className="filter-css">
            <form className="align-items-end row" novalidate>
              <div className="mb-4 col-xl-3 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom03" className="form-label">
                    Gateway Name
                  </label>
                  <select
                    name="gateway"
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    value={gateway}
                    onChange={handlerChange}
                  >
                    <option value="">{"Select"}</option>
                    {allGatewayList.map((it, _i) => {
                      return (
                        <option value={it.name} key={_i}>
                          {it.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-4 col-xl-3 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom03" className="form-label">
                    Product
                  </label>
                  <select
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    name="product"
                    value={product}
                    onChange={handlerChange}
                  >
                    <option value="">{"Select"}</option>
                    {allProducts.map((it, _i) => {
                      return (
                        <option value={it.value} key={_i}>
                          {it.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-4 col-xl-3 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom03" className="form-label">
                    Order ID
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    name="orderId"
                    value={orderId}
                    onChange={handlerChange}
                  />
                </div>
              </div>

              <div className="mb-4 col-xl-3 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom05" className="form-label">
                    Transaction status
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom05"
                    name="status"
                    value={status}
                    onChange={handlerChange}
                  />
                </div>
              </div>

              <div className="mb-4 col-xl-3 col-md-6 col-12">
                <button className="btn btn-submit btnver-2" onClick={fetchData}>
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

export default PaymentServiceFilter;
