import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Inputs from "../../../../CustomComponent/Inputs";
import Select from "../../../../CustomComponent/Select";
import Button from "../../../../CustomComponent/Button";
import { allProducts } from "../../../Apitracking/PaymentGateway/Modal/AddPaymentGateway";
import {
  addHdfceSessionApi,
  updateHdfceSessionApi,
} from "../../../../Services/Api/HdfcSessionMgmt";
import Multiselect from "multiselect-react-dropdown";

const UpdateHdfcSession = ({
  allSource,
  fetchData,
  setShowModal,
  editData,
}) => {
  const [fieldData, setFieldData] = useState({
    ...editData,
    selectedProduct: [],
    selectedSource: [],
  });
  const [load, setLoad] = useState(false);
  const {
    source,
    siteId,
    currency,
    product,
    session,
    selectedProduct,
    selectedSource,
  } = fieldData;
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };
  const handleMultiSelect = (e, name) => {
    console.log({ e, name });
    let value = e
      .map((it) => (name === "product" ? it.value : it.name))
      .join(",");
    setFieldData({ ...fieldData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { selectedSource, selectedProduct, ...restData } = fieldData;
    setLoad(true);
    try {
      const { data } = await updateHdfceSessionApi(restData);
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}

    setLoad(false);
  };
  useEffect(() => {
    const newRes1 = editData.product.split(",").map((it) => {
      return { ["name"]: it, ["value"]: it };
    });
    const newRes2 = editData.source.split(",").map((it) => {
      return { ["name"]: it };
    });
    setFieldData({
      ...fieldData,
      selectedProduct: newRes1,
      selectedSource: newRes2,
    });
  }, [editData]);

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Form.Group className="input-classicsite">
              <label className="form-label">Source</label>
              <Multiselect
                displayValue="name"
                placeholder="Select Meta List"
                className="multiselect-cs"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={(e) => handleMultiSelect(e, "source")}
                onSearch={function noRefCheck() {}}
                onSelect={(e) => handleMultiSelect(e, "source")}
                options={allSource.data}
                selectedValues={selectedSource}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="siteId"
              placeholder="Enter the Site ID"
              label="Site ID"
              onChange={handleChange}
              required={true}
              classstyle="form-control"
              value={siteId}
              //  disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Form.Group className="input-classicsite">
              <label className="form-label">Product</label>
              <Multiselect
                displayValue="name"
                placeholder="Select Meta List"
                className="multiselect-cs"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={(e) => handleMultiSelect(e, "product")}
                onSearch={function noRefCheck() {}}
                onSelect={(e) => handleMultiSelect(e, "product")}
                options={allProducts}
                selectedValues={selectedProduct}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="currency"
              placeholder="Enter the currency code"
              label="Currency"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={currency}
              //  disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="session"
              placeholder="Enter the session"
              label="Session"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={session}
              //  disabled={isEditdata ? true : false}
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title={load ? "Please Wait..." : "Submit"}
              //  disabled={load}
              color="btn btn-submit btnver-2  btn-md "
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default UpdateHdfcSession;
