import React, { useState } from "react";
import Pagination from "../../../CustomComponent/Pagination";
import { useParams } from "react-router";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import JSONPretty from "react-json-prettify";
import Table from "../../../CustomComponent/Table";
const PaymentTableInfo = ({ paymentData }) => {
  const { totalPage, contentData } = paymentData;
  const [show, setShow] = useState(false);
  const [jsonRes, setJsonRes] = useState("");
  const query = useParams();
  return (
    <>
      <div className="container-fluid px-3 mb-4 mt-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          <div className="table-responsive lightthm-wrap scroll-auto">
            <table className="table table-lightthm text-left">
              <thead className="bg-light">
                <tr>
                  <th width={20}>S.no.</th>
                  <th>Gateway Name</th>
                  <th>Order ID</th>
                  <th>GID</th>
                  <th>Product</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Request</th>
                  <th>Response</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {contentData.length > 0 ? (
                  contentData.map((item, i) => (
                    <Table
                      i={i}
                      data={[
                        i + 1,
                        item.gateway,
                        item.orderId,
                        item.gid || "NA",
                        item.product || "NA",
                        new Date(item.date).toDateString(),
                        new Date(item.time).toLocaleTimeString(),
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>View Request</Tooltip>}
                        >
                          <button
                            className="btn btn-sm btn-soft btn-soft-green"
                            onClick={() => {
                              setShow(true + "-Req");
                              setJsonRes(item.request);
                            }}
                          >
                            Request{" "}
                            <i
                              className="fa fa-reply ms-1"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </OverlayTrigger>,
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>View Response</Tooltip>}
                        >
                          <button
                            className="btn btn-sm btn-soft btn-soft-green"
                            onClick={() => {
                              setShow(true + "-Res");
                              setJsonRes(item.response);
                            }}
                          >
                            Response{" "}
                            <i
                              className="fa fa-reply ms-1"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </OverlayTrigger>,
                        item.status,
                      ]}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      No Payment Service Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {totalPage > 1 && (
            <div className="blog_bxrowpagi_empty">
              <Pagination
                totalPage={totalPage}
                currentPage={+query.id || 1}
                linkUrl="/payment-serivce/page/"
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        show={!show ? show : show.split("-")[0]}
        onHide={() => setShow(false)}
        size="xl"
        className="modalbooknow-classic"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {show && show.split("-")[1] === "Req" ? "Request" : "Response"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="reqpre">
          {jsonRes.includes("{") ? (
            <p className="black">
              {typeof jsonRes == "object" ? (
                <JSONPretty json={jsonRes} />
              ) : jsonRes.includes("400 : ") ? (
                <JSONPretty
                  json={JSON.parse(
                    jsonRes
                      .replace("400 : ", "")
                      .replace('"{', "{")
                      .replace('}"', "}")
                  )}
                />
              ) : jsonRes.includes('{"') && jsonRes.includes('"}') ? (
                <JSONPretty json={JSON.parse(jsonRes)} />
              ) : (
                <JSONPretty json={JSON.parse(jsonRes)} />
              )}
            </p>
          ) : (
            <p>{jsonRes}</p>
          )}
        </Modal.Body>
        <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
          <button
            className="btn btn btn-submit btnver-2"
            onClick={() => setShow(false)}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentTableInfo;
