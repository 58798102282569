import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import PaymentBar from "../../Molecule/PaymentDetail/PaymentBar";
import PaymnetFilter from "../../Molecule/PaymentDetail/PaymentFilter";
import PaymentInfo from "../../Molecule/PaymentDetail/PaymentInfo";

const PaymentDetail = () => {
  const [filterObj, setFilterObj] = useState({
    from: 0,
    to: 0,
    orderId: "",
    transactionID: "",
    transactionstatus: "",
    gatewayName: "",
    userId: "",
  });

  return (
    <div>
      {/* <PaymentBar Pagetitle={`Payment Detail`}/> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        <PaymnetFilter setFilterObj={setFilterObj} />
        <PaymentInfo filterObj={filterObj} />
      </div>
    </div>
  );
};

export default PaymentDetail;
