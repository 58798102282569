import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ totalPage, currentPage, linkUrl = "" }) => {
  return (
    <div>
      <div className="blog_bxrowpagi_empty"></div>
      <div className="col-12 pt-3 pagination-footer">
        <nav className="text-center ">
          <ul className="pagination justify-content-end blog_bxrowpagi flex-wrap mb-0">
            {currentPage > 1 && (
              <li className="nv page-item itemnv-1">
                <Link
                  className="page-link"
                  aria-label="Previous"
                  to={`${linkUrl}${1}`}
                >
                  <span aria-hidden="true">«</span>
                </Link>
              </li>
            )}
            {currentPage > 1 && (
              <li className="nv page-item itemnv-2">
                <Link
                  className="page-link"
                  aria-label="Previous"
                  to={`${linkUrl}${currentPage - 1}`}
                >
                  <span aria-hidden="true">‹</span>
                </Link>
              </li>
            )}
            {Array(totalPage)
              .fill()
              .map((item, i) => {
                return (
                  <li
                    className={`page-item dots ${
                      currentPage === i + 1 ||
                      currentPage - 1 === i + 1 ||
                      currentPage + 1 === i + 1 ||
                      currentPage + 2 === i + 1 ||
                      currentPage - 2 === i + 1
                        ? "activedot"
                        : ""
                    } ${currentPage === i + 1 && "active"}`}
                  >
                    <Link className="page-link" to={`${linkUrl}${i + 1}`}>
                      {i + 1}
                    </Link>
                  </li>
                );
              })}
            {currentPage < totalPage && (
              <li className="nv page-item itemnv-1">
                <Link className="page-link" aria-label="Previous">
                  <span aria-hidden="true" to={`${linkUrl}${currentPage + 1}`}>
                    ›
                  </span>
                </Link>
              </li>
            )}
            {currentPage < totalPage && (
              <li className="nv page-item itemnv-2">
                <Link
                  className="page-link"
                  aria-label="Previous"
                  to={`${linkUrl}${totalPage}`}
                >
                  <span aria-hidden="true">»</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
