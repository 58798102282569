import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import axios from "axios";
import { pay_int_url } from "../../../Host";
import { encryptDataWithMode } from "../../../utils/encryptData";
import Swal from "sweetalert2";
import { commonheader } from "../../../utils/header";

const AuthReversalModal = ({ fetchData, setShowAuth }) => {
  const [load, setLoad] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const handleSubmit = async (e) => {
    setLoad(true);
    const scrKey = "ZEhKaGRtOVFRMGxFVTFNeU1ESXpNalJ3WVhsdFpXNTA=";
    e.preventDefault();
    const encryptData = encryptDataWithMode(
      {
        merchantTxnId: orderId,
        agent: localStorage.getItem("name"),
      },
      scrKey
    );
    try {
      const { data } = await axios.post(
        `${pay_int_url}/payglocal/reversal`,
        {},
        {
          headers: {
            "session-props": encryptData,
            ...commonheader(),
          },
        }
      );
      if (data.httpStatus === "OK") {
        fetchData();
        setShowAuth(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };
  return (
    <div>
      {" "}
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="orderId"
                placeholder="Enter the Order ID"
                label="Order ID"
                onChange={(e) => setOrderId(e.target.value)}
                required={true}
                classstyle="form-control mt-4"
                value={orderId}
              />
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Submit"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default AuthReversalModal;
